<template>
  <PrimePreloader v-if="loading"/>
  <div v-if="eqiShortAnswers === false" class="test-eqi">
    <div v-if="checkAccess === 0"><h1 class="text-center mt-3 mb-3">Время сдачи теста еще не настало!</h1></div>
    <div v-else>
      <div class="container test-description" v-if="isStarted === 0">
        <h2 class="text-center">Дорогой студент,</h2>
        <h3 class="mt-3 text-center">{{ description }}</h3>
        <button class="btn btn-primary btn-lg mt-2" value="a" @click="startTest">Начать тест</button>
      </div>
      <div class="container" v-else>
        <div v-if="index_question !== eqiShortQuestions.length">
          <h1 class="text-center mt-3 mb-3">Тест Эмоциональный Интеллект (EQI)</h1>
          <Knob style="display: inline-block" class="me-3 mb-3" v-model="index_question" :min="1" :max="51" readonly />
          <h3 style="display: inline-block">{{ question }}</h3>
          <div v-if="rare_answers.find(i => i === Number(index_question)+1)">
            <div class="eqi-buttons-div d-flex flex-row align-items-center" v-for="(score, index) in eqiShortScore2"
                 :key="index">
              <button class="eqi-buttons" :value=score.score @click="chooseAnswer"></button>
              <h4 style="margin: 0">{{ score.text }}</h4>
            </div>
          </div>
          <div v-else>
            <div class="eqi-buttons-div d-flex flex-row align-items-center" v-for="(score, index) in eqiShortScore"
                 :key="index">
              <button class="eqi-buttons" :value=score.score @click="chooseAnswer"></button>
              <h4 style="margin: 0">{{ score.text }}</h4>
            </div>
          </div>
          <button class="btn btn-secondary btn-lg mt-3" value="a" @click="backAnswer" v-if="index_question > 0">Назад
          </button>
        </div>
        <div v-else class="text-center">
          <h1 class="text-center mt-3 mb-3">Тест Эмоциональный Интеллект (EQI)</h1>
          <button class="btn btn-secondary btn-lg me-1" value="a" @click="backAnswer">Назад</button>
          <button class="btn btn-success btn-lg" @click="endTest">Завершить</button>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="eqiShortAnswers[0] === null" class="test-try-again">
    <div class="container">
      <h2 class="m-3">Вы ответили на вопросы не искренне. Попробуйте сдать тест еще раз после:</h2>
      <h1 class="text-center display-2">{{ eqiShortAnswers[1] }}</h1>
    </div>
  </div>
  <div class="test-results" v-else>
    <div class="container">
      <div v-if="eqiShortAnswers.length > 0" style="width: 60%; height: 60%; margin: 0 auto;" class="results-chart">
        <Chart type="radar" :data="chartData" :options="chartOption"/>
      </div>
      <div style="overflow: auto">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Шкала</th>
            <th scope="col">Описание</th>
            <th scope="col">Уровень</th>
            <th scope="col">Комментарий</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in eqiShortAnswers" :key="index">
            <td>{{ item.eqi_short_rate_name }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.rate_group_name }}</td>
            <td>{{ item.description }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "BigFive",
  data() {
    return {
      loading: true,
      rare_answers: [6, 18, 21, 36, 46],
      question: '',
      index_question: null,
      answers: [],
      isStarted: false,
      description: 'Вам предлагается заполнить опросник, состоящий из 51 утверждения, описывающие  Ваши эмоции, мысли и поведение в большинстве повторяющихся ситуациях. Пожалуйста, внимательно прочтите каждое утверждение и поставьте галочку в той графе, которая лучше всего отражает Ваше мнение.',
      eqiShortScore: [
        {id: "1", score: "1", text: "Нет"},
        {id: "2", score: "2", text: "Иногда"},
        {id: "3", score: "3", text: "Регулярно"},
        {id: "4", score: "4", text: "Часто"},
        {id: "5", score: "5", text: "Очень часто"},
      ],
      eqiShortScore2: [
        {id: "1", score: "1", text: "Нет"},
        {id: "2", score: "2", text: "Иногда"},
        {id: "3", score: "3", text: "Регулярно"},
        {id: "4", score: "4", text: "Часто"},
        {id: "5", score: "5", text: "Очень часто(Да)"}
      ],

      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Результат',
            backgroundColor: 'rgba(240, 128, 128, 0.5)',
            borderColor: 'rgba(240, 128, 128, 0.5)',
            pointBackgroundColor: 'rgba(240, 128, 128, 0.5)',
            pointBorderColor: 'rgba(240, 128, 128, 0.5)',
            pointHoverBackgroundColor: 'rgba(240, 128, 128, 0.5)',
            pointHoverBorderColor: 'rgba(240, 128, 128, 0.5)',
            data: []
          }
        ]
      },
      chartOption: {
        events: [],
        plugins: {
          legend: {
            display: false
          }
        },
        scales: {
          r: {
            min: 0,
            max: 130
          }
        }
      }
    }
  },
  methods: {
    ...mapActions('eqiShort', ['GET_EQI_SHORT_QUESTIONS', 'POST_EQI_SHORT_ANSWERS', 'GET_EQI_SHORT_ANSWERS']),
    ...mapActions('checkAccess', ['GET_CHECK_ACCESS']),

    startTest() {
      this.isStarted = 1
      localStorage.setItem('is-started', JSON.stringify(1))
    },

    getAnswers() {
      return JSON.parse(localStorage.getItem('answers'))
    },

    getResults() {
      let chartLabels = []
      let datasetsData = []
      for (let object of this.eqiShortAnswers) {
        chartLabels.push('Шкала - ' + object.eqi_short_rate_name.toUpperCase())
        datasetsData.push(object.standart_score)
      }
      this.chartData.labels = chartLabels
      this.chartData.datasets[0].data = datasetsData
    },

    chooseAnswer(e) {
      let answer = {eqi_short_questions_id: this.eqiShortQuestions[this.index_question].id, score: e.target.value}
      let answers = this.getAnswers()
      answers.push(answer)

      this.index_question++
      if (this.index_question < this.eqiShortQuestions.length) {
        this.question = this.eqiShortQuestions[this.index_question].name
      }

      localStorage.setItem('index_question', this.index_question)
      localStorage.setItem('answers', JSON.stringify(answers))
    },

    backAnswer() {
      let answers = this.getAnswers()
      answers.pop()

      this.index_question--
      this.question = this.eqiShortQuestions[this.index_question].name

      localStorage.setItem('answers', JSON.stringify(answers))
      localStorage.setItem('index_question', this.index_question)
    },

    async endTest() {
      await this.POST_EQI_SHORT_ANSWERS(this.getAnswers())
      localStorage.removeItem('answers')
      localStorage.removeItem('index_question')
      await this.GET_EQI_SHORT_ANSWERS();
      await this.getResults();
      this.$message({title: "Действие выполнено!"})
    }
  },
  computed: {
    ...mapState('eqiShort', ['eqiShortQuestions', 'eqiShortAnswers']),
    ...mapState('checkAccess', ['checkAccess'])
  },
  async mounted() {
    await this.GET_CHECK_ACCESS('eqShort');
    await this.GET_EQI_SHORT_ANSWERS();

    if (this.eqiShortAnswers === false) {
      await this.GET_EQI_SHORT_QUESTIONS();

      if (!localStorage.getItem('index_question') && !localStorage.getItem('answers')) {
        await localStorage.setItem('is-started', '0')
        await localStorage.setItem('index_question', '0')
        await localStorage.setItem('answers', '[]')
      }

      this.isStarted = Number(localStorage.getItem('is-started'));
      this.index_question = Number(localStorage.getItem('index_question'))
      this.question = this.eqiShortQuestions[this.index_question].name
    } else {
      await this.getResults()
    }
    console.log(this.isStarted)
    this.loading = false;
  }
}
</script>

<style scoped>
.eqi-buttons {
  background-color: #2196F3;
  border-radius: 50%;
  border: none;
  margin: 0.25rem 12px 0.25rem 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
}

.test-description button {
  display: flex;
  margin: 0 auto;
}
</style>